export const HOME_PAGE_STRINGS: Record<string, string | JSX.Element> = {
  'meta.title': 'Property Management Made Loveable | Belong',
  'meta.description':
    'No more landlords and tenants. We’re making renting magical for homeowners and residents, with guaranteed rent, property management, and more.',
  'header.title': 'The Home Renting Experience People\u00a0Love',
  'header.subtitle':
    'No more landlords or tenants. We’re making renting\u00a0magical for homeowners\u00a0and\u00a0residents.',
  'header.ownersCta': 'For Homeowners',
  'header.rentersCta': 'For Renters',
  videoTitle: 'Belong Residents will love your home just\u00a0as much as\u00a0you\u00a0do.',
  ctasTitle: "Join the world's first residential\u00a0network.",
  'cta1.title': 'Own a rental home?',
  'cta1.body':
    "We partner with you to achieve financial\u00a0freedom. And you'll never need\u00a0to\u00a0lift a\u00a0finger.",
  'cta1.ctaLabel': 'For Homeowners',
  'cta2.title': (
    <span>
      Renting that builds
      <br />
      towards homeownership
    </span>
  ),
  'cta2.body':
    'Find the perfect home to love, receive exceptional care, and save part of rent each\u00a0month to buy\u00a0a\u00a0home.',
  'cta2.ctaLabel': 'See Homes',
  'cta.manage.title': (
    <span>
      {'Manage your\u00a0home,'}
      <br />
      {'right\u00a0from your\u00a0phone.'}
    </span>
  ),
  'cta.manage.subtitle':
    'With the Belong App, manage rent, request a Pro, or message your Member Success Team, whenever and wherever you\u00a0need\u00a0to.',
  testimonialsTitle: "There's a lot of love to\u00a0go\u00a0around.",
  'referralCard.subtitle': 'Refer a great homeowner and get $1,000 when their lease\u00a0starts.',
  'clickableSection.title': 'For Homeowners',
  'clickableSection.subtitle':
    'Guaranteed rent and more, whether you want to manage it yourself, or hand us\u00a0the\u00a0keys.',
  'cliackableSection.belongPro.subtitle': 'FOR FULL MANAGEMENT',
  'cliackableSection.belongPro.description':
    'Hand us the keys, and never worry about your rental\u00a0home\u00a0again.',
  'cliackableSection.belongX.subtitle': 'FOR SELF MANAGERS',
  'cliackableSection.belongX.description':
    'Rental home leasing & financial protection, for those that like to manage things themselves.',

  'resident-product-compare.title': 'For Residents',
  'resident-product-compare.subtitle':
    'Rent a Belong home, or simply pay rent better, and build towards homeownership.',
  'resident-product-compare.belongPro.description': 'Much-loved homes for\nmuch-loved Residents.',
  'resident-product-compare.belongPro.cta': 'VIEW HOMES',

  'resident-product-compare.belongX.description': 'Build credit with seamless,\nflexible rent\u00a0payments.',

  'featuredCities.title': 'Featured Cities & Neighborhoods Where We Operate',
  compare: 'Compare',
};
